





































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import AppVue from "@/AppVue.vue";
import {
  SearchService,
  IUIFilters,
  TurnstoneLookupsModel,
  IUICompanyFilters,
  SavedCompanySearchService,
  SavedSearchService,
} from "@/core/services";
import {
  SearchResults,
  SearchModel,
  SearchResultContainer,
  Filter,
  Field,
  DateRangeFilter,
  CompanySearchResultModel,
  SavedSearchModel,
  FreeTextSearchType,
} from "@/core/models";
import {
  CompanySearchResultItemCpt,
  CompanySearchResultDetailsCpt,
  ResultsBlankSlateCpt,
  CompanySearchFilterBarCpt,
} from "./components";

import SearchFilterBarCpt, {
  events as SearchBarEvents,
} from "@/modules/search/components/SearchFilterBarCpt.vue";

import EventBus from "@/core/components/this.$eventHub.vue";
import { HeaderEvents } from "@/layouts/components/HeaderCpt.vue";
import { MapEvents, LocationObject } from "../map/MapView.vue";
import { Route, RawLocation, Location } from "vue-router";
import { WatcherService } from "@/core/services";
import { routeStack } from "@/core/services/routeStack.service";
import { BreadCrumbsCpt } from "@/core/components";
import { CreateCompanyExportCpt, CreateExportCpt } from "../export/components";

@Component({
  components: {
    CompanySearchResultItemCpt: () =>
      Promise.resolve(CompanySearchResultItemCpt),
    CompanySearchResultDetailsCpt: () =>
      Promise.resolve(CompanySearchResultDetailsCpt),
    BreadCrumbsCpt: () => Promise.resolve(BreadCrumbsCpt),
    CreateCompanyExportCpt: () => Promise.resolve(CreateCompanyExportCpt),
    ResultsBlankSlateCpt: () => Promise.resolve(ResultsBlankSlateCpt),
    CompanySearchFilterBarCpt: () => Promise.resolve(CompanySearchFilterBarCpt),
  },
  async beforeRouteEnter(to, from, next) {
    routeStack.clear();
    await WatcherService.companyList(0);
    const savedState = SearchService.loadCompanySearch();
    if (savedState && savedState.model && savedState.model.results) {
      savedState.model.results.forEach(
        (x) =>
          (x.document.isWatched = WatcherService.isCompanyWatched(
            Number(x.document.companyId),
          )),
      );
    }
    const differentFilters =
      Number(to.params.filterId) !== Number(savedState.currentFilter);
    const validSavedFilter = !!savedState.currentFilter;

    if (
      to.params.clearCache === "1" ||
      (differentFilters && validSavedFilter)
    ) {
      to.meta.savedState = {};
    } else {
      to.meta.savedState = savedState;
    }
    next();
  },
  async beforeRouteUpdate(to, from, next) {
    routeStack.clear();

    if (to.params.reload === "false") {
      return next();
    }
    const c = this as CompanySearchResultsView;
    if (to && to.params && !to.params.cached) {
      await c.initialize(to.params);
    }
    next();
  },
  beforeRouteLeave(to, from, next) {
    const c = this as CompanySearchResultsView;
    const cacheObject = {
      model: c.model,
      filters: c.filters,
      builtFilter: c.filter,
      currentFilter: c.currentFilter,
      currentRoute: from,
    };
    SearchService.saveCompanySearch(cacheObject);
    next();
  },
})
export default class CompanySearchResultsView extends AppVue {
  searchId: string = "";
  cachedSearch: boolean = false;
  showExportDialog = false;
  filters: IUICompanyFilters = {
    polyline: [],
    counties: Array<string>(),
    roles: Array<string>(),
    geoRange: {
      center: [],
      range: 0,
    },
    dateUpdated: new DateRangeFilter(),
    query: "",
    queryType: FreeTextSearchType.Any,
  };
  model = {} as SearchResults<CompanySearchResultModel>;
  filter = {} as SearchModel;
  loading = false;
  map = {};
  details: CompanySearchResultModel = new CompanySearchResultModel();
  skip = 0;
  currentFilter = 0;
  showFilterDialog = false;
  showSaveDialog = false;
  pageSize = 100;
  showMapOnMobile = false;
  renamingId: number = 0;
  saveSearchSaveAs: string = "New";
  savedSearchList: any[] = [];
  saveSearchAsExistingID = 0;
  newName: string = "";
  tourSteps: any[] = [
    {
      target: ".search-box",
      content: `Discover <strong>Vue Tour</strong>!`,
    },
    {
      target: ".filters",
      content: "An awesome plugin made with Vue.js!",
    },
  ];
  get buttonClass() {
    return this.$isMobile() ? "mini" : "small";
  }
  showFilters() {
    this.showFilterDialog = true;
  }
  endRename(hideId: number) {
    if (hideId === this.renamingId) {
      this.newName = "";
      this.renamingId = 0;
    }
  }
  startRename(model: SearchModel) {
    this.newName = model.name;
    this.renamingId = model.id;
  }
  async renameSearch(model: any, newName: string) {
    if (model.id === this.renamingId) {
      let res = new SavedSearchModel();
      res = await SavedCompanySearchService.renameSavedSearch(
        this.renamingId,
        this.newName,
      );
      this.$emit("updated", res);
      model.isRenaming = false;
      this.filter.name = res.name;
    }
  }
  mounted() {
    super.updateTitle("Search Results");
    this.$eventHub.$emit(MapEvents.ClearDrawLayer);
    this.$eventHub.$emit(MapEvents.Clear);
    routeStack.addTitle(this.$route, "Search Results");
  }
  unwatchCompany(item: any) {
    this.$nextTick(() => {
      item.document.isWatched = false;
      this.$set(item.document, "isWatched", false);
    });
  }
  watchCompany(item: any) {
    this.$nextTick(() => {
      item.document.isWatched = true;
      this.$set(item.document, "isWatched", true);
    });
  }

  detailClosing() {
    this.details = new CompanySearchResultModel();
    this.$eventHub.$emit("closeDetailView");
    this.$eventHub.$emit(MapEvents.Refresh);
  }
  async savedSearchTypeChange() {
    if (this.saveSearchSaveAs === "As") {
      this.savedSearchList = await SavedCompanySearchService.list(0);
    }
  }
  async created() {
    this.$eventHub.$on("MarkerClicked", this.markerClick);

    this.$eventHub.$on(SearchBarEvents.ClearFilters, this.clearFilters);
    this.$eventHub.$on(SearchBarEvents.FilterUpdated, this.filterUpdated);
    this.$eventHub.$on(
      HeaderEvents.SearchQueryChanged,
      this.searchQueryChanged,
    );
    this.$eventHub.$on(SearchBarEvents.ToggleMap, this.toggleMap);
    await this.initialize(this.$route.params, this.$route.meta);
  }

  destroyed() {
    this.$eventHub.$off("MarkerClicked", this.markerClick);
    this.$eventHub.$off(SearchBarEvents.ClearFilters, this.clearFilters);
    this.$eventHub.$off(SearchBarEvents.FilterUpdated, this.filterUpdated);
    this.$eventHub.$off(SearchBarEvents.ToggleMap, this.toggleMap);
    this.$eventHub.$off(
      HeaderEvents.SearchQueryChanged,
      this.searchQueryChanged,
    );
  }
  markerClick(item: LocationObject) {}

  searchQueryChanged(form: any) {
    this.filters.query = form.query;
    this.filters.queryType = form.queryType;
    this.filtersUpdated();
  }

  clearFilters(filters: any) {
    this.filters = filters;

    this.filtersUpdated();
  }

  removeFilterFromUrl() {
    const currentFilterId = Number(this.$route.params.filterId);
    const filterId = Number(this.filter.id);
    this.filter.name = "";
    this.filter.id = 0;
    this.newName = "";
    this.saveSearchSaveAs = "New";
    if (!!currentFilterId) {
      this.$router
        .push({
          name: this.$route.name || "",
          params: {
            query: this.filters.query || "",
            queryType: this.filters.queryType.toString(),
            resultsLoaded: "100",
          },
        } as RawLocation)
        .catch(() => {});
    }
  }

  filterUpdated(filters: any) {
    this.filters = {
      ...filters,
      query: this.filters.query,
      queryType: this.filters.queryType,
    };
    this.filter.name = "";
    this.filter.id = 0;
    this.filtersUpdated();
  }
  setBreadCrumbName() {
    routeStack.push(this.$route);
    routeStack.addTitle(
      this.$route,
      `Search Results (${this.model.totalRecords})`,
    );
  }
  processCache(meta?: any) {
    if (meta && meta.savedState.model && meta.savedState.filters) {
      this.model.compiledSearch = meta.savedState.model.compiledSearch;
      this.model.results = meta.savedState.model.results;
      this.model.facets = meta.savedState.model.facets;
      this.model.totalRecords = meta.savedState.model.totalRecords;
      this.currentFilter = meta.savedState.currentFilter;
      this.updateUrl(true);
      this.filters = { ...this.filters, ...meta.savedState.filters };
      this.filter = meta.savedState.builtFilter;
      this.addMarkers(this.model.results);
      this.$nextTick(() => {
        this.setBreadCrumbName();
        const selectedItem = this.model.results.find(
          (x) => x.document.selected,
        );
        if (selectedItem) {
          this.$eventHub.$emit("ScrollToResult", {
            resultId: selectedItem.document.companyId,
          });
          this.selectItem(selectedItem);
        }
        this.$eventHub.$emit(SearchBarEvents.SetDefaultFilters, this.filters);
      });
      return true;
    }
    return false;
  }

  updateUrl(cached: boolean) {
    const params: any = {
      ...this.$route.params,
      resultsLoaded: this.model.results.length.toString(),
      reload: "false",
    };
    if (cached) {
      params.cached = "true";
    }
    if (this.currentFilter) {
      params.filterId = this.currentFilter.toString() || "0";
    }
    if (
      Number(params.filterId) !== Number(this.$route.params.filterId) ||
      Number(params.resultsLoaded) !== Number(this.$route.params.resultsLoaded)
    ) {
      if (this.$route.params === params) {
        return;
      }

      this.$router
        .push({
          name: this.$route.name || "",
          params,
        } as RawLocation)
        .catch(() => {});
    }
  }
  async initialize(params: any, meta?: any) {
    this.skip = 0;
    this.$eventHub.$emit(MapEvents.Clear);

    if (!this.processCache(meta)) {
      this.currentFilter = Number(params.filterId);
      if (!!this.currentFilter) {
        this.loading = true; // start the loading now. the search at the end of the road will turn it off
        this.filter = await SavedCompanySearchService.getSingle(
          this.currentFilter,
        );
        this.filters = SearchService.convertCompanySearchModelToFilters(
          this.filter,
        );
        this.$eventHub.$emit(MapEvents.Clear);
        // setup save search stuff
        this.saveSearchSaveAs = "As";
        this.saveSearchAsExistingID = this.filter.id;
        this.$nextTick(() => {
          this.drawAreasOnTheMapIfSaved();
        });
        this.$eventHub.$emit(SearchBarEvents.SetDefaultFilters, this.filters);
      } else {
        this.filters.query = params.query || "";
        this.buildFilter();
      }
      this.$nextTick(() => {
        this.search(false, params.resultsLoaded); // search manages the loading status
      });
    } else {
      // if it is a search from cache, update the skip value
      this.skip = this.model.results.length - this.pageSize;
    }
  }
  drawAreasOnTheMapIfSaved() {
    if (!!this.filters.polyline && this.filters.polyline.length > 0) {
      this.$eventHub.$emit(
        MapEvents.DrawPolylineFromUserInput,
        this.filters.polyline,
      );
    } else if (
      !!this.filters.geoRange &&
      !!this.filters.geoRange.center &&
      !!this.filters.geoRange.range
    ) {
      this.$eventHub.$emit(MapEvents.DrawCircleFromUserInput, {
        center: this.filters.geoRange.center,
        radius: this.filters.geoRange.range,
      });
    }
  }

  filtersUpdated() {
    // coming from a redirect (the table/map duality).
    // do not remove the filter, this is not a "new query".
    if (this.$route.params.keepFilter == "1") {
      this.$route.params.keepFilter = "";
      return;
    }
    this.showFilterDialog = false;
    this.skip = 0;
    this.buildFilter();
    this.removeFilterFromUrl();
    this.$eventHub.$emit(MapEvents.Clear);
    this.search(false);
  }

  async search(append: boolean, resultsLoaded?: number) {
    try {
      this.loading = true;
      this.detailClosing();
      // close all filters?
      this.$eventHub.$emit("FilterDialogOpened", "none");
      if (append) {
        const newResults = await SearchService.searchCompany(
          this.filters.query,
          this.pageSize,
          this.skip,
          this.filter,
          this.searchId,
        );
        newResults.results.forEach(
          (x) =>
            (x.document.isWatched = WatcherService.isCompanyWatched(
              Number(x.document.companyId),
            )),
        );
        newResults.results.forEach((x) => this.model.results.push(x));
        this.addMarkers(newResults.results);
      } else {
        this.model = await SearchService.searchCompany(
          this.filters.query,
          resultsLoaded || this.pageSize,
          this.skip,
          this.filter,
          this.searchId,
        );
        this.searchId = this.model.searchId;
        this.model.results.forEach(
          (x) =>
            (x.document.isWatched = WatcherService.isCompanyWatched(
              Number(x.document.companyId),
            )),
        );
        this.addMarkers(this.model.results);
        if (this.model.results.length > 0) {
          this.$eventHub.$emit("ScrollToResult", {
            resultId: this.model.results[0].document.companyId,
          });
        }
      }
      this.setBreadCrumbName();
    } finally {
      this.loading = false;
    }
  }

  addMarkers(results: Array<SearchResultContainer<CompanySearchResultModel>>) {
    this.$nextTick(() => {
      const markers = results.filter((x) => x.document.location);
      this.$eventHub.$emit(
        MapEvents.AddCompanyMarkerCollection,
        markers.map((m) => ({
          resultId: Number(m.document.companyId),
          data: m.document,
          latLong: {
            lat: m.document.location.latitude,
            lng: m.document.location.longitude,
          },
          type: "company",
          zoom: 13,
          pin: true,
          text: m.document.name,
        })),
      );
    });
  }

  selectItem(item: SearchResultContainer<CompanySearchResultModel>) {
    // unselect selected
    this.model.results
      .filter((x) => x.document.selected)
      .forEach((x) => (x.document.selected = false));
    // select current
    item.document.selected = true;
    this.$eventHub.$emit("openDetailView");
    this.details = item.document;
    super.updateTitle(`${item.document.name}`);

    if (item.document.location) {
      // we show the details
      // then we tell the map to focus, but only on the next render
      // so the map can be recentered correctly - FR
      this.$nextTick(() => {
        this.$eventHub.$emit(MapEvents.Focus, {
          resultId: item.document.companyId,
          data: item.document,
          pin: true,
          zoom: 18,
          latLong: {
            lat: item.document.location.latitude,
            lng: item.document.location.longitude,
          },
        });
      });
    } else {
      this.$nextTick(() => {
        this.$eventHub.$emit(MapEvents.Center);
      });
    }
  }

  loadMore() {
    this.skip = this.skip + this.pageSize;
    this.search(true).then(() => {
      this.updateUrl(false);
    });
  }

  resetFilter() {
    this.filter = {
      id: this.filter.id,
      name: this.filter.name,
      freeText: this.filters.query,
      freeTextType: this.filters.queryType,
      fields: Array<Field>(),
    } as SearchModel;
  }
  buildFilter() {
    this.resetFilter();
    const results: Field[] = SearchService.buildCompanyFilter(
      this.filters.polyline,
      this.filters.geoRange,
      this.filters.counties,
      this.filters.roles,
      [this.filters.dateUpdated],
    );
    results.forEach((x) => this.filter.fields.push(x));
  }

  startTour() {
    this.$tours.searchTour.start();
  }

  openSaveDialog() {
    this.newName = this.filter.name;
    this.showSaveDialog = !this.showSaveDialog;
    this.savedSearchTypeChange();
    if (this.saveSearchSaveAs === "New") {
      const nameInput = this.$refs["nameInput"] as Vue;
      const input = nameInput.$refs["input"] as any;
      this.$nextTick(() => {
        input.focus();
      });
    }
  }
  async catchEnter(keyUpEvent: KeyboardEvent) {
    if (keyUpEvent.keyCode === 13) {
      await this.save();
    }
  }
  async save() {
    let savedSearch;
    if (this.saveSearchSaveAs === "As") {
      savedSearch = await SavedCompanySearchService.update(
        this.saveSearchAsExistingID,
        this.filter,
      );
    } else {
      this.filter.name = this.newName;
      savedSearch = await SavedCompanySearchService.save(this.filter);
    }

    this.currentFilter = savedSearch.userAzureSearchID;
    this.showSaveDialog = false;
    this.$router
      .push({
        params: {
          filterId: savedSearch.userAzureSearchID.toString(),
        },
      })
      .catch(() => {});

    const messageObj: any = {
      title: "Saved",
      message: "Search saved",
      type: "success",
    };
    this.$notify(messageObj);
  }

  toggleMap(value: boolean) {
    this.showMapOnMobile = value;
  }

  get mobileMapClass() {
    return this.showMapOnMobile ? "mobile-show-map" : "";
  }
}
