
















































































































































import { Component, Vue } from "vue-property-decorator";
import { WatcherService } from "@/core/services";
import { LabeledSectionCpt } from "@/core/components";
import {} from "@/core/models";

import AppVue from "@/AppVue.vue";
import {
  SearchService,
  IUIFilters,
  TurnstoneLookupsModel,
  IUICompanyFilters,
  SavedCompanySearchService,
} from "@/core/services";

import {
  SearchResults,
  SearchModel,
  SearchResultContainer,
  Filter,
  Field,
  DateRangeFilter,
  CompanySearchResultModel,
} from "@/core/models";
import { roles as Roles, roleFlag } from "@/core/constants";
import CompanySearchResultsView from "./CompanySearchResultsView.vue";
import { routeStack } from "@/core/services/routeStack.service";
@Component({
  components: {},
  async beforeRouteEnter(to, from, next) {
    routeStack.clear();
    await WatcherService.companyList(0);
    const savedState = SearchService.loadCompanySearch();

    const differentFilters = Number(to.params.filterId) !== Number(savedState.currentFilter);
    const validSavedFilter = !!savedState.currentFilter;

    if (to.params.clearCache === "1" || (differentFilters && validSavedFilter)) {
      to.meta.savedState = {};
    } else {
      to.meta.savedState = savedState;
    }
    next();
  },
  async beforeRouteUpdate(to, from, next) {
    routeStack.clear();

    if (to.params.reload === "false") {
      return next();
    }
    const c = this as CompanySearchResultTableView;
    if (to && to.params && !to.params.cached) {
      await c.initialize(to.params);
    }
    next();
  },
  beforeRouteLeave(to, from, next) {
    const c = this as CompanySearchResultTableView;
    const cacheObject = {
      model: c.model,
      filters: c.filters,
      builtFilter: c.filter,
      currentFilter: c.currentFilter,
      currentRoute: from,
    };
    SearchService.saveCompanySearch(cacheObject);
    next();
  },
})
export default class CompanySearchResultTableView extends CompanySearchResultsView {
  roles: any = { icons: roleFlag, ...Roles };
  getIcon(role: string) {
    return this.roles.icons[role] ?? this.roles.icons.Default;
  }
}
