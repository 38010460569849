




































































































































































































































































































import { Component } from "vue-property-decorator";

import { SearchResultItemCpt, SearchResultDetailsCpt } from "./components";
import {
  BreadCrumbsCpt,
  SectorIconCpt,
  ForgetProjectButtonCpt,
} from "@/core/components";
import { CreateExportCpt } from "@/modules/export/components";
import TrackingProjectCpt from "@/modules/tracking/components/TrackingProjectCpt.vue";
import ProjectTableNotificationCpt from "@/modules/search/components/ProjectTableNotificationCpt.vue";

// services
import { routeStack } from "@/core/services/routeStack.service";
import { SearchService } from "@/core/services";
import { WatcherService } from "@/core/services";
import ProjectSearchResults from "./ProjectSearchResultsView.vue";

@Component({
  components: {
    SearchResultItemCpt: () => Promise.resolve(SearchResultItemCpt),
    SearchResultDetailsCpt: () => Promise.resolve(SearchResultDetailsCpt),
    BreadCrumbsCpt: () => Promise.resolve(BreadCrumbsCpt),
    CreateExportCpt: () => Promise.resolve(CreateExportCpt),
    SectorIconCpt: () => Promise.resolve(SectorIconCpt),
    TrackingProjectCpt: () => Promise.resolve(TrackingProjectCpt),
    ProjectTableNotificationCpt: () =>
      Promise.resolve(ProjectTableNotificationCpt),
    ForgetProjectButtonCpt: () => Promise.resolve(ForgetProjectButtonCpt),
  },
  async beforeRouteEnter(to, from, next) {
    routeStack.clear();
    await WatcherService.projectList(0);
    const savedState = SearchService.loadProjectSearch();
    const differentFilters =
      Number(to.params.filterId) !== Number(savedState.currentFilter);
    const validSavedFilter = !!savedState.currentFilter;

    if (
      to.params.clearCache === "1" ||
      (differentFilters && validSavedFilter)
    ) {
      to.meta.savedState = {};
    } else {
      to.meta.savedState = savedState;
    }

    next();
  },
  async beforeRouteUpdate(to, from, next) {
    routeStack.clear();

    if (to.params.reload === "false") {
      return next();
    }
    const c = this as ProjectSearchResultTableView;
    if (to && to.params && !to.params.cached) {
      await c.initialize(to.params);
    }
    next();
  },
  beforeRouteLeave(to, from, next) {
    const c = this as ProjectSearchResultTableView;
    const cacheObject = {
      model: c.model,
      filters: c.filters,
      builtFilter: c.filter,
      currentFilter: c.currentFilter,
      currentRoute: from,
    };
    SearchService.saveProjectSearch(cacheObject);

    next();
  },
})
export default class ProjectSearchResultTableView extends ProjectSearchResults {}
